import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { environment as env } from '@environments/environment';
import { CodeInputComponent } from '@modules/shared/components/code-input/code-input.component';
import { UserService } from '@services/auth/user.service';

@Component({
  selector: 'app-sign-up-referral-step',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModules, CodeInputComponent],
  templateUrl: './sign-up-referral-step.component.html',
  styleUrl: './sign-up-referral-step.component.scss',
})
export class SignUpReferralStepComponent implements OnInit {
  private _referralCode?: string;
  form: FormGroup;
  correctFormat: boolean = false;

  referralCodeValid?: boolean;

  @Input() set referralCode(referralCode: string | undefined) {
    this._referralCode = referralCode?.toUpperCase();
    if (this._referralCode) this.checkFormat(this._referralCode);
  }
  get referralCode(): string | undefined {
    return this._referralCode?.toUpperCase();
  }

  @Input() isWrongCode = false;
  @Input() signUpIsInProgress: boolean = false;

  @Output() next = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
  ) {
    this.form = this.fb.group({
      referralCode: [''],
    });
  }

  ngOnInit(): void {
    this.form.get('referralCode')?.patchValue(this.referralCode);
    this.form.get('referralCode')?.valueChanges.subscribe((value) => {
      this.referralCode = value.toUpperCase();
      this.checkFormat(value);
    });
  }

  signUp(): void {
    this.next.emit(this.referralCode);
  }

  checkFormat(code: string) {
    const regex = /^[A-Za-z0-9]{6}$/;
    this.correctFormat = regex.test(code);

    if (this.referralCode && this.correctFormat) {
      this.userService.checkReferralCodeExists(this.referralCode).subscribe({
        next: (exists: boolean) => {
          this.referralCodeValid = exists;
        },
        error: () => {
          this.referralCodeValid = false;
        },
      });
    } else if (
      this.referralCode &&
      this.referralCode.length === 6 &&
      !this.correctFormat
    ) {
      this.referralCodeValid = false;
    } else {
      this.referralCodeValid = undefined;
    }
  }

  onNoCode(): void {
    if (this.signUpIsInProgress) {
      return;
    }

    this.next.emit();
  }

  onCodeCompleted() {
    if (this.correctFormat && this.referralCode) {
      this.next.emit(this.referralCode);
    }
  }

  protected readonly env = env;
}
