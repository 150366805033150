import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { finalize } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-sign-up-email-form',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModules, MatCheckboxModule],
  templateUrl: './sign-up-email-form.component.html',
  styleUrl: './sign-up-email-form.component.scss',
})
export class SignUpEmailFormComponent implements OnInit, OnChanges {
  form?: FormGroup;
  inProgress = false;

  showCoHostEmailChangedHint = false;

  @Input() email?: string;
  @Input() invitedCoHostEmail?: string;

  @Output() emailEntered: EventEmitter<string> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl(this.email || this.invitedCoHostEmail || '', [
        Validators.required,
        Validators.email,
      ]),
      acceptedPrivacyPolicy: new FormControl(false, Validators.requiredTrue),
      acceptedTerms: new FormControl(false, Validators.requiredTrue),
    });

    if (this.invitedCoHostEmail && this.invitedCoHostEmail !== '') {
      this.form.controls['email'].valueChanges.subscribe((val) => {
        this.showCoHostEmailChangedHint = val !== this.invitedCoHostEmail;
      });
    }
  }

  ngOnChanges(): void {
    this.form?.controls['email'].setValue(this.email);
  }

  signUp(): void {
    if (!this.form) {
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.inProgress = true;

    const email = this.form.value.email;

    let params: Map<string, any> = new Map<string, any>();
    params.set('isSignUp', true);

    this.authService
      .userOrAccountWithEmailExists(email, params)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe({
        next: (exists: boolean) => {
          if (!exists) {
            this.emailEntered.emit(email);
          } else {
            this.form?.controls['email'].setErrors({
              custom: this.translateService.instant(
                'APP.AUTH.ERRORS.EMAIL_EXISTS',
              ),
            });
          }
        },
      });
  }
}
