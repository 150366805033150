<form class="form phone-form" [formGroup]="form" (keyup.enter)="signUp()">
  <mat-form-field
    class="w-100"
    [class.has-value]="form.controls['phone'].value !== ''"
    appearance="outline"
  >
    <app-tel-input formControlName="phone"></app-tel-input>
    @if (form.controls["phone"].hasError("custom")) {
      <mat-error>
        {{ form.controls["phone"].getError("custom") }}
      </mat-error>
    } @else if (form.controls["phone"].hasError("required")) {
      <mat-error>
        {{ "APP.AUTH.ERRORS.PHONE_REQUIRED" | translate }}
      </mat-error>
    } @else if (form.controls["phone"].hasError("invalidNumber")) {
      <mat-error>
        {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
      </mat-error>
    }
  </mat-form-field>
  <div class="terms-cont mb-20">
    <div class="terms-cont-wrapper">
      <mat-checkbox formControlName="acceptedTerms"
        >{{ "APP.I_AGREE_TO" | translate }}
        <a href="https://eventpage.ai/terms-of-use/" target="_blank">{{
          "APP.THE_TERMS_OF_USE" | translate
        }}</a></mat-checkbox
      >
      <mat-checkbox formControlName="acceptedPrivacyPolicy"
        >{{ "APP.I_CONSENT_TO" | translate }}
        <a href="https://eventpage.ai/datenschutzerklaerung/" target="_blank">{{
          "APP.THE_DATA_PRIVACY" | translate
        }}</a></mat-checkbox
      >
    </div>
  </div>
  <button
    type="button"
    class="btn btn-primary-party-page"
    [class.spinner]="inProgress"
    (click)="signUp()"
    [disabled]="inProgress"
  >
    {{ "APP.AUTH.SIGN_UP" | translate }}
  </button>
</form>
