@if (form) {
  <form class="form" [formGroup]="form" (keyup.enter)="signUp()">
    <mat-form-field
      class="w-100"
      [class.has-value]="
        form.controls['email'].value && form.controls['email'].value !== ''
      "
      appearance="outline"
    >
      <mat-label>{{ "APP.AUTH.EMAIL_ADDRESS" | translate }}</mat-label>
      <input type="email" matInput formControlName="email" placeholder="" />
      @if (showCoHostEmailChangedHint) {
        <mat-hint class="co-host-changed-hint">
          <div
            [innerHTML]="
              'APP.CO_HOST_INVITATION.INVITATION_EMAIL_CHANGED_HINT'
                | translate: { email: invitedCoHostEmail }
            "
          ></div>
        </mat-hint>
      }
      @if (form.controls["email"].hasError("custom")) {
        <mat-error>
          {{ form.controls["email"].getError("custom") }}
        </mat-error>
      } @else if (
        form.controls["email"].hasError("email") &&
        !form.controls["email"].hasError("required")
      ) {
        <mat-error>{{ "APP.AUTH.ERRORS.EMAIL_INVALID" | translate }}</mat-error>
      } @else if (form.controls["email"].hasError("required")) {
        <mat-error>
          {{ "APP.AUTH.ERRORS.EMAIL_REQUIRED" | translate }}
        </mat-error>
      }
    </mat-form-field>
    <div class="terms-cont mb-20">
      <div class="terms-cont-wrapper">
        <mat-checkbox formControlName="acceptedTerms"
          >{{ "APP.I_AGREE_TO" | translate }}
          <a href="https://eventpage.ai/terms-of-use/" target="_blank">{{
            "APP.THE_TERMS_OF_USE" | translate
          }}</a></mat-checkbox
        >
        <mat-checkbox formControlName="acceptedPrivacyPolicy"
          >{{ "APP.I_CONSENT_TO" | translate }}
          <a
            href="https://eventpage.ai/datenschutzerklaerung/"
            target="_blank"
            >{{ "APP.THE_DATA_PRIVACY" | translate }}</a
          ></mat-checkbox
        >
      </div>
    </div>
    <button
      type="button"
      class="btn btn-primary-party-page"
      [class.spinner]="inProgress"
      (click)="signUp()"
      [disabled]="inProgress"
    >
      {{ "APP.AUTH.SIGN_UP" | translate }}
    </button>
  </form>
}
