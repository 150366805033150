import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalSettings } from '@models/users/global_settings.model';
import { UserService } from '@services/auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsStore {
  private _globalSettings: BehaviorSubject<GlobalSettings | undefined> =
    new BehaviorSubject<GlobalSettings | undefined>(undefined);

  private _errorMessage: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  public readonly globalSettings: Observable<GlobalSettings | undefined> =
    this._globalSettings.asObservable();

  public readonly errorMessage: Observable<string | undefined> =
    this._errorMessage.asObservable();

  constructor(private userService: UserService) {}

  getGlobalSettings() {
    this.userService.getGlobalSettings().subscribe({
      next: (globalSettings) => {
        this._globalSettings.next(globalSettings);
      },
      error: (error) => {
        if (error?.error) {
          this._errorMessage.next(error.error);
        }
      },
    });
  }

  setGlobalSettings(globalSettings: GlobalSettings) {
    this._globalSettings.next(globalSettings);
  }
}
